import { CanadianProvince, UsState } from '../answers';
import { FinancialActivityType } from './typecodes';

type Extension = Record<string, any>;

// eslint-disable-next-line @typescript-eslint/no-namespace
export enum RoleType {
  insured = 'insured',
  owner = 'owner',
  payer = 'payer',
}

export enum AddressTypeCode {
  billing = 'billing',
  home = 'home',
  mailing = 'mailing',
  other = 'other',
  previous = 'previous',
  shipping = 'shipping',
  work = 'work',
}

export enum PhoneTypeCode {
  cell = 'cell',
  home = 'home',
  work = 'work',
}

export enum GenderCode {
  male = 'male',
  female = 'female',
  other = 'other',
  unknown = 'unknown',
}

export enum PartyIdentityCode {
  ssn = 'SSN',
  tin = 'TIN',
  id = 'ID',
}

export enum NameCode {
  dba = 'DBA',
  aka = 'AKA',
}

export enum BeneficiaryTypeCode {
  primary = 'primary',
  contingent = 'contingent',
}

export enum CoverageIndicatorCode {
  base = 'base',
  rider = 'rider',
  unknown = 'unknown',
}

export enum RelationshipCode {
  brother = 'brother',
  brotherInLaw = 'brotherInLaw',
  businessMgr = 'businessMgr',
  charity = 'charity',
  church = 'church',
  corporation = 'corporation',
  daughter = 'daughter',
  daughterPerStirpes = 'daughterPerStirpes',
  daughterInLaw = 'daughterInLaw',
  domesticPartner = 'domesticPartner',
  employer = 'employer',
  estate = 'estate',
  exSpouse = 'exSpouse',
  father = 'father',
  fatherInLaw = 'fatherInLaw',
  fiance = 'fiance',
  fosterChild = 'fosterChild',
  grandchild = 'grandchild',
  grandchildPerstirpe = 'grandchildPerstirpe',
  granddaughter = 'granddaughter',
  grandfather = 'grandfather',
  grandmother = 'grandmother',
  grandson = 'grandson',
  guardian = 'guardian',
  mother = 'mother',
  motherInLaw = 'motherInLaw',
  nephew = 'nephew',
  niece = 'niece',
  other = 'other',
  parent = 'parent',
  sister = 'sister',
  sisterInLaw = 'sisterInLaw',
  son = 'son',
  sonPerStirpes = 'sonPerStirpes',
  sonInLaw = 'sonInLaw',
  spouse = 'spouse',
  stepFather = 'stepFather',
  stepMother = 'stepMother',
  stepchild = 'stepchild',
  trust = 'trust',
  trustee = 'trustee',
}

export enum MaritalStatusCode {
  married = 'married',
  single = 'single',
  widowed = 'widowed',
  divorced = 'divorced',
  civilUnionDomesticPartnership = 'civilUnionDomesticPartnership',
}

export enum InsuredTypeCode {
  primary = 'primary',
}

export enum AgentTypeCode {
  writing = 'writing',
  servicing = 'servicing',
  individual = 'individual',
}

export enum JurisdictionCode {
  CA = 'CA',
  CO = 'CO',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  ND = 'ND',
  OH = 'OH',
  OR = 'OR',
  SD = 'SD',
  WA = 'WA',
  WI = 'WI',
}

export enum ProductTypeCode {
  base = 'base',
  rider = 'rider',
}

export type AcordJsonInputData = {
  number?: string;
  insured: Insured[];
  owner: Owner[];
  payer: Payer[];
  beneficiary: Beneficiary[];
  product: Product[];
  agent: Agent[];
  premium: Premium;
  payment: Payment;
  signatureInfo: SignatureInfo;
  jurisdiction: JurisdictionCode;
  financialActivity?: FinancialActivity;
  extension?: Extension;
};

export type Party = {
  name?: Name[];
  age?: number;
  address?: Address[];
  communication?: Communication[];
  genderCode?: string;
  birthDate?: string;
  partyIdentity?: PartyIdentity[];
  maritalStatus?: MaritalStatusCode;
  extension?: Extension;
};

export type FinancialActivity = {
  finActivityType: FinancialActivityType;
};

export type PartyIdentity = {
  typeCode: PartyIdentityCode;
  typeId: string;
  extension?: Extension;
};

export type NameBase = {
  givenName: string;
  otherGivenName?: string;
  surname: string;
};

export type Name = NameBase & {
  id: string;
  typeCode?: NameCode;
};

export type AddressBase = {
  line1: string;
  line2: string;
  city: string;
  stateOrProvinceCode: CanadianProvince | UsState;
  postalCode: string;
};

export type Communication = {
  id: string;
  phoneNumber: string;
  phoneTypeCode: PhoneTypeCode;
  emailAddress?: string;
  primaryIndicator: boolean;
};

export type Address = AddressBase & {
  id: string;
  typeCode: AddressTypeCode;
};

export type Relationship = {
  id: string;
  description: string;
  relationshipToCode: RelationshipCode;
};

export type Coverage = {
  id: string;
  amount?: number;
  indicatorCode: CoverageIndicatorCode;
  productCode: string;
};

export type Risk = {
  id: string;
  typeCode: string;
};

export type Product = {
  id: string;
  typeCode: string;
  coverage: Coverage[];
  risk?: Risk;
  extension?: Extension;
};

export type Employee = {
  id: string;
  companyName: string;
  employeePay: EmployeePay;
};

export type EmployeePay = {
  id: string;
  amount: number;
};

export type Insured = Party & {
  id: string;
  typeCode: string;
  occupation: string;
  employee: Employee;
  extension?: Extension;
};

export type Owner = Party & {
  id: string;
  relationship?: Relationship;
  extension?: Extension;
};

export type Payer = Party & {
  id: string;
  extension?: Extension;
};

export type Payment = {
  id: string;
  frequencyCode: string;
};

export type Premium = {
  id: string;
  amount: number;
};

export type Beneficiary = Party & {
  id: string;
  typeCode: BeneficiaryTypeCode;
  coverageId: string;
  insuredId: string;
  relationship?: Relationship;
  extension?: Extension;
};

export type Agent = Party & {
  id: string;
  number: string;
  typeCode: AgentTypeCode;
  extension?: Extension;
};

export type SignatureInfo = {
  id: string;
  formState: string;
  extension?: Extension;
};
