export enum FieldSizes {
  full = 12,
  threeQuarters = 9,
  twoThirds = 8,
  half = 6,
  third = 4,
  quarter = 3,
}

type FieldSizesOption = { label: string; value: FieldSizes };

export const getFieldLayoutOptions = (t: (key: FieldSizes) => string): FieldSizesOption[] => {
  const options = [
    FieldSizes.full,
    FieldSizes.threeQuarters,
    FieldSizes.twoThirds,
    FieldSizes.half,
    FieldSizes.third,
    FieldSizes.quarter,
  ];

  return options.map((option) => ({ label: t(option), value: option }));
};

export type FieldLayout = {
  size?: FieldSizes;
  forceNewLine?: boolean;
};
