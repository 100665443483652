import { Conditions, Query } from './conditions';

export type EngineEffects = {
  beforeValueUpdate?: SetValueEffect[];
  afterValueUpdate?: SetValueEffect[];
};

export type SetValueEffect = {
  at: string;
  value: EffectValue;
  triggerIf?: Conditions;
  newAnswerMustMatch?: unknown;
};

export type EffectValue = string | boolean | number | Query | SetValueEffectSpecialValue | undefined;

export enum SetValueEffectSpecialValue {
  newId = '{{new_id}}',
}
